<template>
  <div>
    <header class="fixed navbar dark shadowed">
      <div class="container">
        <section class="brand">
          <img src="/images/flodio-logo.svg" @click="toggleMenu"/>
        </section>
        <section class="links">
          <div class="section">
            <div class="buttons">
              <router-link class="button" :to="{name: 'Flows'}">Flows</router-link>
              <router-link class="button" :to="{name: 'MyFlows'}">My Flows</router-link>
              <router-link class="button" :to="{name: 'ImportFlow'}">Import Flow</router-link>
            </div>
          </div>
          <div class="section">
            <div class="form lr-40">
              <Search/>
            </div>
          </div>
          <div class="section">
            <div class="buttons">
              <a href="javascript:" class="button">
                <span class="material-icons">person</span>
                &nbsp;Account
              </a>
            </div>
          </div>
        </section>
      </div>
    </header>
    <div class="burger menu" v-show="expanded">
      <div class="item" @click="navigate">
        <router-link :to="{name: 'Flows'}">Flows</router-link>
      </div>
      <div class="item" @click="navigate">
        <router-link :to="{name: 'MyFlows'}">My Flows</router-link>
      </div>
      <div class="item" @click="navigate">
        <router-link :to="{name: 'ImportFlow'}">Import Flow</router-link>
      </div>
    </div>

  </div>
</template>

<script>
import Search from "@/components/Search.vue"

export default {
  name: "MainMenu",
  components: {
    Search
  },
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    toggleMenu() {
      this.expanded = !this.expanded
    },
    navigate() {
      this.expanded = false
    }
  }
}
</script>

<style lang="scss">
.burger.menu {
  position: absolute;
  width: 50%;
}
</style>