<template>
  <div id="app">
    <div class="tab">
      <div class="spacer"></div>
      <a class="button" :class="{ active: direction === 'vertical'}" @click="changeDirection('vertical')">
        <i class="material-icons">swap_vert</i>
      </a>
      <a class="button" :class="{ active: direction === 'horizontal'}" @click="changeDirection('horizontal')">
        <i class="material-icons">swap_horiz</i>
      </a>
    </div>
    <VerticalFlow
        v-if="flow"
        v-show="direction === 'vertical'"
        :flow="flow"
        @titleChanged="updateTitle"
        @contentChanged="updateContent"
        @input="input"
        @imageUploaded="imageUploaded"
        @editStep="editStep"
        @save="save"
    />
    <HorizontalFlow
        v-if="flow"
        v-show="direction === 'horizontal'"
        :flow="flow"
        @titleChanged="updateTitle"
        @contentChanged="updateContent"
        @markStepComplete="markStepComplete"
        @input="input"
        @done="done"
    />
  </div>
</template>

<script>
import VerticalFlow from '@/components/VerticalFlow'
import HorizontalFlow from '@/components/HorizontalFlow'
import api from '@/services/api'
import Vue from 'vue'

export default {
  name: 'Flow',
  components: {
    VerticalFlow,
    HorizontalFlow,
  },
  data() {
    return {
      flow: {
        steps: []
      },
      direction: 'horizontal',
    }
  },
  async mounted() {
    const id = this.$route.params.id
    this.flow = await api.rest.get(`/flow-runs/${id}`)
  },
  methods: {
    changeDirection(newDirection) {
      this.direction = newDirection
    },
    updateTitle(step, value) {
      step.title = value
    },
    updateContent(step, value) {
      step.content = value
    },
    markStepComplete(step) {
      console.log("completed a step")
      step.completedAt = step.completedAt || new Date()
      this.flow.progress = this.flow.steps.reduce((acc, s) => s.completedAt ? acc + 1.0 : acc, 0.0) / this.flow.steps.length
      api.rest.put(`/flow-runs/${this.flow.id}`, this.flow)
      console.log(this.flow.progress)
    },
    input(step, text) {
      step.input = text
      this.save(this.flow)
    },
    done() {
      this.direction = 'vertical'
    },
    imageUploaded(img, index) {
      this.flow.steps.splice(index, 0, {
        image: img,
        editing: true,
        title: '',
        content: ''
      })
    },
    save(flow, step, newStep) {
      step.editing = false
      step.content = newStep.content
      step.image = newStep.image
      step.title = newStep.title
      api.rest.put(`/flow-runs/${flow.id}`, flow)
    },
    editStep(step) {
      Vue.set(step, 'editing', true)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
