
function upload(file) {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.addEventListener("load", function () {
      resolve(reader.result)
    }, false)

    if (file) {
      reader.readAsDataURL(file);
    }
  })
}

export { upload }