<template>
  <div class="activity">
    <div class="type" :class="stateClass">
      <span class="material-icons">{{ icon }}</span>
    </div>
    <div class="title">
      <div class="grid">
        <div class="row">
          <h4 class="col-15">{{ step.title }}</h4>
          <div class="col-1 small buttons">
            <button class="tiny link button" @click="editStep">
              <span class="material-icons">edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="body" v-if="!step.inputType">{{ step.content }}</div>
    <div class="date">{{ step.completedAt | moment("from", "now") }}</div>
    <div class="author">{{ step.completedBy }}</div>
    <div class="media" v-if="step.image">
      <img :src="step.image" />
    </div>
    <div class="form" v-if="step.inputType">
      <div class="item">
        <input
          :type="step.inputType"
          v-model="step.input"
          :placeholder="step.content"
          autofocus
          @input="input"
          @keyup="keyup"
          ref="input"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VerticalActivity',
    props: {
      step: {
        image: String,
        title: String,
        content: String,
        editing: false,
        completedBy: String,
        completedAt: String,
        inputType: String,
        input: String,
      },
    },
    computed: {
      stateClass() {
        return this.step.completedAt ? 'success' : ''
      },
      icon() {
        return this.step.completedAt ? 'check' : 'play_arrow'
      },
    },
    methods: {
      input(event) {
        this.$emit('input', event.target.value, this.step)
      },
      keyup(event) {
        if (event.keyCode == 13) {
          this.$emit('forward', this.step)
        }
      },
      activate() {
        this.$refs.input.scrollIntoView()
        this.$refs.input.focus()
      },
      editStep() {
        this.$emit('editStep', this.step)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .form {
    padding: 0;
  }
</style>
