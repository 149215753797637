<template>
  <div class="panel pure">
    <div class="header"><h4>Type or paste your flow description</h4></div>
    <div class="content">
      <form class="form">
        <input type="text" v-model="name" placeholder="Name your new flow"/>

        <div class="row">
          <div class="col-lg-8">
            <markdown-editor :model="text" @input="input"/>
          </div>
          <div class="col-lg-8">
            <mermaid :nodes="mermaidSrc" type="graph TD"/>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-16">
            <div class="checkbox">
              <input id="clarify" type="checkbox" v-model="clarify" @change="update"/>
              <label for="clarify">Clarify steps</label>
            </div>
            <div class="checkbox">
              <input id="debug" type="checkbox" v-model="debug"/>
              <label for="debug">Show grammar</label>
            </div>
          </div>
        </div>
        <div v-html="svg" v-if="debug"/>
        <!-- <div>{{ mermaidSrc }}</div> -->
      </form>
    </div>
    <div class="buttons">
      <button class="button" @click="save">Save this flow</button>
    </div>
  </div>
</template>

<script>
import MarkdownEditor from 'vue-simplemde'
import Mermaid from '@/components/Mermaid'
import api from '@/services/api'
import router from '@/router'

export default {
  name: 'ImportFlow',
  data() {
    return {
      debug: false,
      mermaidSrc: [],
      name: '',
      text: 'Hej allesammen',
      clarify: true,
      svg: '',
    }
  },
  methods: {
    async input(value) {
      this.text = value
      await this.update()
    },
    async update() {
      const result = await api.spacy.toMermaid(this.text, this.clarify)
      this.mermaidSrc = result.mermaid_source
      this.svg = result.displacy
    },
    async save() {
      const definition = await api.rest.post('/flow-definitions', {steps: this.mermaidSrc, name: this.name})
      router.push({name: 'FlowDefinition', params: {id: definition.id}})
    }
  },
  components: {
    MarkdownEditor,
    Mermaid,
  },
}
</script>

<style lang="sass">
@import '~simplemde/dist/simplemde.min.css'
</style>
