<template>
  <div class="activity">
    <div class="type">
        <span class="material-icons primary">add</span>
    </div>
    <div class="title">
      <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
        <div class="dropbox">
          <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="save($event.target.files); fileCount = $event.target.files.length"
            accept="image/*" class="input-file">
            <p v-if="isInitial">
              Drag screenshot here
            </p>
            <p v-if="isSaving">
              Uploading {{ fileCount }} files...
            </p>
        </div>
      </form>
    </div>
    <!--SUCCESS-->
    <div v-if="isSuccess" class="media">
      <p>
        <a href="javascript:void(0)" @click="reset()">Cancel</a>
      </p>
    </div>
    <!--FAILED-->
    <div v-if="isFailed" class="content">
      <h2>Uploaded failed.</h2>
      <p>
        <a href="javascript:void(0)" @click="reset()">Try again</a>
      </p>
      <pre>{{ uploadError }}</pre>
    </div>      
  </div>
</template>

<script>
  import { upload } from '../services/file-upload.fake.service';

  const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3

  export default {
    name: 'Upload',
    props: {
      index: Number
    },
    data() {
      return {
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'photos'
      }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
    },
    methods: {
      reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadError = null;
      },
      save(files) {
        // upload data to the server
        this.currentStatus = STATUS_SAVING;

        upload(files[0])
          .then(imageData => {
            this.currentStatus = STATUS_SUCCESS;
            this.$emit('uploaded', imageData, this.index)
            this.reset()
          })
          .catch(err => {
            this.uploadError = err.response;
            this.currentStatus = STATUS_FAILED;
          });
      },
    },
    mounted() {
      this.reset();
    },
  }

</script>

<style lang="scss" scoped>
  .activity {
    opacity: 0.5;
  }

  .dropbox {
    outline: 1px dashed #eee;
    outline-offset: -10px;
    color: #bbb;
    padding: 5px 5px;
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    outline: 2px dashed #aaa;
    color: #666;
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 10px 0 0 0;
  }
</style>
