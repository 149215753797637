<template>
  <div class="container" v-if="flow">
    <h1>
      {{ flow.name }}
      <div class="author" v-if="flow.definition">Author: {{ flow.definition.author.name }}</div>
    </h1>
    <div class="grid">
      <div class="row">
        <div class="col-lg-3 col-xs-0">
          <div class="one cards">
            <HorizontalActivity v-if="prevStep" :step="prevStep" :key="prevStep.title" class="faded"/>
          </div>
        </div>
        <div class="col-lg-10 col-xs-16">
          <div class="one cards">
            <HorizontalActivity v-if="currentStep" :step="currentStep" :key="currentStep.title" @input="input"
                                @forward="forward" ref="currentActivity">
              <div class="buttons block">
                <button class="button" @click="backward" :class="{ disabled: !prevStep }"
                ><i class="material-icons">keyboard_arrow_left</i></button
                >
                <div class="spacer"></div>
                <button class="button" :class="{ primary: nextStep, success: !nextStep }" @click="forward"
                ><i class="material-icons">{{ nextStepIcon }}</i></button
                >
              </div>
            </HorizontalActivity>
          </div>
        </div>
        <div class="col-lg-3 col-xs-0">
          <div class="one cards">
            <HorizontalActivity v-if="nextStep" :step="nextStep" :key="nextStep.title" class="faded"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalActivity from '@/components/HorizontalActivity'
import debounce from '@/services/debounce'

export default {
  name: 'HorizontalFlow',
  components: {
    HorizontalActivity,
  },
  props: {
    flow: {
      steps: [{title: '', content: ''}],
      definition: {
        author: {name: ''}
      }
    },
  },
  data() {
    return {
      currentStep: this.flow.steps[0],
      transitionName: 'forward',
    }
  },
  watch: {
    flow() {
      this.currentStep = this.currentStep || this.flow.steps[0]
    }
  },
  computed: {
    nextStep() {
      const index = this.flow.steps.indexOf(this.currentStep)
      if (index < this.flow.steps.length - 1) {
        return this.flow.steps[index + 1]
      }
      return null
    },
    prevStep() {
      const index = this.flow.steps.indexOf(this.currentStep)
      if (index > 0) {
        return this.flow.steps[index - 1]
      }
      return null
    },
    nextStepIcon() {
      return this.nextStep ? 'keyboard_arrow_right' : 'check'
    },
  },
  methods: {
    async forward() {
      this.transitionName = 'forward'
      await this.markComplete()
      if (this.nextStep) {
        this.currentStep = this.nextStep
        this.$nextTick(function () {
          this.$refs.currentActivity.activate()
        })
      } else {
        this.$emit('done')
      }
    },
    backward() {
      this.transitionName = 'backward'
      this.currentStep = this.prevStep || this.currentStep
    },
    markComplete() {
      this.$emit('markStepComplete', this.currentStep)
    },
    input: debounce(function (text, step) {
      this.$emit('input', step, text)
    }, 1000)
  },
}
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;

  .author {
    margin-top: -10px;
    color: #888;
    font-size: 0.35em;
  }
}

.faded {
  opacity: 0.4;
}
</style>
