<template>
  <div class="activity">
    <div class="type">
      <span class="material-icons primary">add</span>
    </div>
    <div class="title">
      <div class="grid">
        <div class="row">
          <div class="col-15"><img class="small" :src="editingStep.image"/></div>
          <div class="col-1">
            <a class="small primary button" @click="save">Save step</a>
          </div>
        </div>
      </div>
      <h6>Title</h6>
      <markdown-editor v-model="editingStep.title"/>
      <h6>Content</h6>
      <markdown-editor v-model="editingStep.content"/>
    </div>
  </div>
</template>

<script>
import MarkdownEditor from 'vue-simplemde'

export default {
  name: 'VerticalActivityEditor',
  props: {
    step: {
      image: '',
      title: '',
      content: '',
    },
  },
  data() {
    return {
      editingStep: {
        image: this.step.image,
        title: this.step.title,
        content: this.step.content,
      }
    }
  },
  components: {
    MarkdownEditor,
  },
  methods: {
    save() {
      this.$emit('save', this.step, this.editingStep)
    },
  },
}
</script>

<style lang="scss">
.CodeMirror, .CodeMirror-scroll {
  height: 100px;
  min-height: 100px;
}

img.small {
  max-height: 100px;
}
</style>
