<template>
  <div class="cards four">
    <div class="card" v-for="flow in flows" v-bind:key="flow.id">
      <div class="title">
        <div class="h4">{{flow.name}}</div>
        <div class="subtitle">Flow subtitle</div>
      </div>
      <div class="description">
        <p>{{ flow.description }}</p>
        <div class="align right">
          <router-link :to="{name: 'FlowDefinition', params: { id: flow.id}}" class="button link">Details</router-link>
          <router-link :to="{ name: 'NewFlow', params: { id: flow.id }}" class="primary button">Start flow</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/services/api'

  export default {
    name: 'Flows',
    data: function () {
      return {
        flows: [],
      }
    },
    async mounted() {
      this.flows = await api.rest.get('/flow-definitions')
    },
  }
</script>
