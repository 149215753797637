<template>
  <div class="cards one">
    <div class="card">
      <div class="title">
        <div class="h4">{{flow.name}}</div>
      </div>
      <div class="description">
        {{flow.description}}
        <div class="align right">
          <router-link :to="{ name: 'NewFlow', params: { id: flow.id }}" class="primary button">Start flow</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/services/api";
  
  export default {
    name: 'FlowDefinition',
    data: function() {
      return {
        flow: {}
      }
    },
    async mounted() {
      const id = this.$route.params.id
      this.flow = await api.rest.get(`/flow-definitions/${id}`)
    }
  };
</script>