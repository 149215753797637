<template>
  <div id="app">
  </div>
</template>

<script>
  import api from '@/services/api'

  export default {
    name: 'NewFlow',
    components: {
    },
    async mounted() {
      const id = this.$route.params.id
      const result = await api.rest.post(`/flow-definitions/${id}/flow-runs`)
      this.$router.replace({ name: 'MyFlow', params: { id: result.id } })
    },
  }
</script>

<style lang="css" scoped></style>
