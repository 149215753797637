import Vue from 'vue'
import VueRouter from 'vue-router'
import Flow from '../views/Flow.vue'
import Flows from '../views/Flows.vue'
import FlowDefinition from '../views/FlowDefinition.vue'
import MyFlows from '../views/MyFlows.vue'
import ImportFlow from '../views/ImportFlow.vue'
import NewFlow from '../views/NewFlow.vue'
import Search from '../views/Search.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MyFlows,
  },
  {
    path: '/flows',
    name: 'Flows',
    component: Flows,
  },
  {
    path: '/flows/:id',
    name: 'FlowDefinition',
    component: FlowDefinition
  },
  {
    path: '/my-flows/new/:id',
    name: 'NewFlow',
    component: NewFlow,
  },
  {
    path: '/flows/import',
    name: 'ImportFlow',
    component: ImportFlow,
  },
  {
    path: '/my-flows/:id',
    name: 'MyFlow',
    component: Flow,
  },
  {
    path: '/my-flows',
    name: 'MyFlows',
    component: MyFlows,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
