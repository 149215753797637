<template>
  <div class="card">
    <div class="title">
      <h4>{{ step.title }}</h4>
    </div>
    <div class="description">
      <span v-if="!step.inputType">{{ step.content }}</span>
      <div class="date">{{ step.completedAt | moment("from", "now") }}</div>
    </div>
    <div class="form" v-if="step.inputType">
      <div class="item">
        <input :type="step.inputType" v-model="step.input" :placeholder="step.content" autofocus @input="input" @keyup="keyup" ref="input"/>
      </div>
    </div>
    <div class="media" :if="step.image">
      <img :src="step.image" />
    </div>
    <div class="actions">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HorizontalActivity',
    props: {
      step: {
        image: String,
        title: String,
        content: String,
        completedBy: String,
        completedAt: String,
        inputType: undefined,
        input: String,
      },
    },
    methods: {
      input(event) {
        this.$emit('input', event.target.value, this.step)
      },
      keyup(event) {
        if (event.keyCode == 13) {
          this.$emit('forward')
        }
      },
      activate() {
        this.$refs.input.focus()
      }
    },
    computed: {},
  }
</script>
