<template>
  <div class="spacy container">
    <table class="hovered table">
      <thead>
        <tr>
          <th>Flow</th>
          <th>Completed</th>
          <th>Started</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="flow in flows" :key="flow.id" @click="clickRow(flow)">
          <td>{{flow.name}}</td>
          <td><progress class="progress" :class="{success: flow.progress > 0.99, warning: flow.progress < 0.7}" :value="100*flow.progress" max="100"></progress></td>
          <td>{{ flow.created_at | moment("from", "now") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import api from '@/services/api'
  export default {
    name: 'MyFlows',

    data() {
      return {
        flows: [],
      }
    },
    methods: {
      clickRow(flow) {
        this.$router.push({ name: 'MyFlow', params: { id: flow.id } })
      },
    },
    async mounted() {
      this.flows = await api.rest.get('/flow-runs')
    },
  }
</script>

<style lang="css" scoped>
  .spacy {
    margin-top: 24px;
  }
</style>
