<template>
  <div class="container" v-if="flow">
    <div class="row">
      <div class="col-lg-4 col-xs-0"/>
      <div class="col-lg-8 col-xs-16">
        <div class="panel pure">
          <header>
            <div class="title">
              <h1>
                {{ flow.name }}
                <div class="author" v-if="flow.definition">Author: {{ flow.definition.author.name }}</div>
              </h1>
            </div>
          </header>
          <div class="timeline">
            <div v-for="(step, i) in flow.steps" :key="step.title">
              <Upload @uploaded="imageUploaded" :index="i"/>
              <VerticalActivity
                  v-if="!step.editing"
                  ref="act"
                  :step="step"
                  @input="input"
                  @forward="forward"
                  @editStep="editStep"/>
              <VerticalActivityEditor
                  v-if="step.editing"
                  :step="step"
                  @save="save"/>
            </div>
            <Upload/>
          </div>
        </div>
        <div class="col-lg-4 col-xs-0"/>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalActivity from '@/components/VerticalActivity'
import VerticalActivityEditor from '@/components/VerticalActivityEditor'
import Upload from '@/components/Upload'
import debounce from '@/services/debounce'

export default {
  name: 'VerticalFlow',
  components: {
    VerticalActivity,
    VerticalActivityEditor,
    Upload,
  },
  props: {
    flow: {
      steps: [{title: '', content: ''}],
      definition: {
        author: {name: ''}
      }
    },
  },
  methods: {
    imageUploaded(img, index) {
      this.$emit('imageUploaded', img, index)
    },
    save(step, newStep) {
      this.$emit('save', this.flow, step, newStep)
    },
    input: debounce(function (text, step) {
      this.$emit('input', step, text)
    }, 1000),
    forward(step) {
      const index = this.flow.steps.indexOf(step)
      if (index < this.flow.steps.length - 1) {
        this.$nextTick(function () {
          this.$refs.act[index + 1].activate()
        })
      }
    },
    editStep(step) {
      this.$emit('editStep', step)
    },
  },
}
</script>

<style lang="scss" scoped>
h1 .author {
  margin-top: -10px;
  color: #888;
  font-size: 0.35em;
}
</style>
