<template>
  <div class="group" v-click-outside="hideDropDown">
    <input type="text" name="query" placeholder="Search..." v-model="query" @input="search" v-on:keyup.enter="search" v-on:focus="focus"/>
    <button class="button dark" v-on:click="search">
      <i class="material-icons">search</i>
    </button>
    <div class="search-preview" v-if="loading || results">
      <div v-if="loading" class="search-preview-message">Searching...</div>
      <div v-if="!loading && !hasResults" class="search-preview-message">Sorry, no results for "{{ query }}"</div>
      <ul v-if="results && !loading" class="search-preview-results">
        <li v-for="result in results" v-bind:key="result.id" @click="navigate">
          <router-link :to="{name: 'FlowDefinition', params: { id: result.id}} ">{{ result.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import debounce from '@/services/debounce'
import ClickOutside from "vue-click-outside";

export default {
  name: 'Search',
  data: function () {
    return {
      query: '',
      loading: false,
      hasResults: false,
      results: undefined,
    }
  },
  methods: {
    search: debounce(async function () {
      if (!this.query || this.query.trim().length < 3) {
        return this.results = undefined
      }
      this.loading = true
      this.hasResults = false
      this.results = await api.rest.get(`/flow-definitions/${this.query}`)
      this.loading = false
      this.hasResults = this.results.length > 0
    }),
    navigate() {
      this.results = false
      this.query = ''
    },
    focus() {
      this.search()
    },
    hideDropDown() {
      this.results = undefined
    },    
  },
  directives: {
    ClickOutside
  }, 
}
</script>

<style lang="scss" scoped>
.search-preview {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #515666;
  color: #fff;
  border: solid 1px #515666;
}

.search-preview-message {
  margin: 10px;
}

.search-preview-results {
  list-style: none;
}

.search-preview-results a {
  color: #fff;
}
</style>
