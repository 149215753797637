import axios from 'axios';

const headers = {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Request-Headers': '*',
};

function toAbsoluteRestAPI(path) {
    if (['development', 'test'].includes(process.env.NODE_ENV)) {
        const l = window.location
        return `${l.protocol}//${l.hostname}:6001${path}`
    }
    return `https://flodio-rest-api.herokuapp.com${path}`
}

function toAbsoluteSpacyAPI(path) {
    if (['development', 'test'].includes(process.env.NODE_ENV)) {
        const l = window.location
        return `${l.protocol}//${l.hostname}:7000${path}`
    }
    return `https://flodio-spacy-api.herokuapp.com${path}`
}

export default {
    spacy: {
        toMermaid(source, clarify) {
            return axios.post(toAbsoluteSpacyAPI('/create/from-plain-text'),
                {doc: source, clarify: clarify},
                headers
            ).then(res => res.data)
        },
    },
    rest: {
        get(path, body) {
            return axios.get(toAbsoluteRestAPI(path), body, headers)
                .then(res => res.data)
        },
        put(path, body) {
            return axios.put(toAbsoluteRestAPI(path), body, headers)
                .then(res => res.data)
        },
        post(path, body) {
            return axios.post(toAbsoluteRestAPI(path), body, headers)
                .then(res => res.data)
        }
    }
};
